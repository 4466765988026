<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>处理异常订单</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card" style="margin-bottom:40px;">
      <div slot="header" class="clearfix" style="text-align:left;">
        <span>正常订单：</span>
        <el-input v-model="billno1" placeholder="输入订单流水单号" clearable @clear="billno1 = ''" style="width:240px;margin:0 10px;" size="small"/>
        <el-button @click="query1()" type="primary" size="small">查询</el-button>
      </div>
      <div class="text item">
        <el-table :data="tableData1" border style="width: 100%">
          <el-table-column label="订单编号" prop="orderId">
            <template slot-scope="scope">
              <div>
                {{ scope.row.orderId }}
                <span v-if="scope.row.isGive == 1" style="font-size:10px;color:green;">（赠送订单）</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="流水单号" prop="billNo">
          </el-table-column>
          <el-table-column label="客户编号" prop="custId">
          </el-table-column>
          <el-table-column label="收件人" prop="receiver">
          </el-table-column>
          <el-table-column label="电话" prop="tel">
          </el-table-column>
          <el-table-column label="地址" prop="tel">
            <template slot-scope="scope">
              {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.distict }}{{ scope.row.address }}
            </template>
          </el-table-column>
          <el-table-column label="金额" prop="total">
            <template slot-scope="scope"> ￥{{ getFlorstr(scope.row.total) }} </template>
          </el-table-column>
          <el-table-column label="订单状态" prop="status">
            <template slot-scope="scope">
              {{ ordertype(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column label="订单详情" prop="orderDetails">
            <template slot-scope="scope">
              {{ filterpro(scope.row.orderDetails) }}
            </template>
          </el-table-column>
          <el-table-column label="时间" prop="createTime" width="200">
            <template slot-scope="scope">
              <div style="font-size:10px;">创建时间:{{ scope.row.createTime }}</div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div class="opt doc">
                <el-button v-if="scope.row.status == 0" size="mini" type="primary" @click="changestate1(scope.row)">修改成已支付</el-button>
                <el-button v-if="scope.row.status == 1" size="mini" type="warning" @click="changestate3(scope.row)">修改成已退款</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix" style="text-align:left;">
        <span>保证金订单：</span>
        <el-input v-model="billno2" placeholder="输入保证金流水单号" clearable @clear="billno2 = ''" style="width:240px;margin:0 10px;" size="small"/>
        <el-button @click="query2()" type="primary" size="small">查询</el-button>
      </div>
      <div class="text item">
        <el-table :data="tableData2" border style="width: 100%">
          <el-table-column label="订单编号" prop="auctionId">
          </el-table-column>
          <el-table-column label="流水单号" prop="billNo">
          </el-table-column>
          <el-table-column label="客户编号" prop="custId">
          </el-table-column>
          <el-table-column label="保证金" prop="deposit">
            <template slot-scope="scope"> ￥{{ scope.row.deposit }}</template>
          </el-table-column>
          <el-table-column label="金额" prop="price">
            <template slot-scope="scope"> ￥{{ getFlorstr(scope.row.price) }} </template>
          </el-table-column>
          <el-table-column label="订单详情">
            <template slot-scope="scope">
              <div>产品名：{{ scope.row.goodsName }}</div>
              <div>规格：{{ scope.row.standardName }}</div>
              <div>数量：{{ scope.row.num }}</div>
            </template>
          </el-table-column>
          <el-table-column label="时间" prop="createTime" width="200">
            <template slot-scope="scope">
              <div style="font-size:10px;">创建时间:{{ scope.row.createTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" prop="status"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div class="opt doc">
                <el-button size="mini" type="primary" @click="changestate2(scope.row)">修改状态</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      billno1: "",
      tableData1: [],
      billno2: "",
      tableData2: [],
      options1: [
        { id: 0, value: "创建订单" },
        { id: 1, value: "待发货订单" },
        { id: 2, value: "已发货订单" },
        { id: 3, value: "确认收货订单" },
        { id: 4, value: "评价" },
        { id: 5, value: "追评" },
        { id: 9, value: "申请售后" },
        { id: 10, value: "退款" },
        { id: 11, value: "换货" },
        { id: 12, value: "拒绝维权请求" },
      ],
    };
  },
  methods: {
    query1() {
      this.tableData1 = [];
      this.searchbillno(
        "/api/admin/ProcessOrderEx/query_order_by_billno",
        this.billno1,
        this.tableData1
      );
    },
    query2() {
      this.tableData2 = [];
      this.searchbillno(
        "/api/admin/ProcessOrderEx/query_auction_order_by_billno",
        this.billno2,
        this.tableData2
      );
    },
    searchbillno(url, str, table) {
      this.axios
        .get(this.serveurl.mallserve + url + "?billNo=" + str)
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.data == null) {
            } else {
              var obj = res.data.data;
              table.push(obj);
            }
          }
        });
    },
    changestate1(row) {
      this.$confirm("是否修改成已支付状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.changebill(
            "/api/admin/ProcessOrderEx/edit_order_pay_status",
            row.billNo,
            this.tableData1
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },
    changestate3(row) {
      this.$confirm("是否修改成已退款状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.changebill(
            "/api/admin/ProcessOrderEx/edit_order_refund_status",
            row.billNo,
            this.tableData1
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },
    changestate2(row) {
      this.$confirm("是否修改状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.changebill(
            "/api/admin/ProcessOrderEx/edit_auction_order_pay_status",
            row.billNo,
            this.tableData2
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },
    changebill(url, str, table) {
      this.axios
        .post(this.serveurl.mallserve + url, this.qs.stringify({ billNo: str }))
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            table = [];
            this.query1();
            this.query2();
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
    filtertime(val) {
      if (val) {
        return val.replace(/\//g, "-");
      }
    },
    getFlorstr(num) {
      num += "";
      num = num.replace(/[^0-9|\.]/g, ""); //清除字符串中的非数字非.字符
      if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, "");
      if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += ".00";
      if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = "0" + num;
      num += "00"; //在字符串末尾补零
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    paytype(val) {
      var str = "";
      for (let a = 0; a < this.paystate.length; a++) {
        if (val == this.paystate[a].id) {
          str = this.paystate[a].value;
        }
      }
      return str;
    },
    ordertype(val) {
      var str = "";
      for (let a = 0; a < this.options1.length; a++) {
        if (val == this.options1[a].id) {
          str = this.options1[a].value;
        }
      }
      return str;
    },
    filterextendId(val) {
      var str = "";
      if (val == "" || val == null || val == "null") {
        str = "暂无";
      } else {
        str = val;
      }
      return str;
    },
    filterpro(row) {
      var str = "";
      if (row) {
        for (let i = 0; i < row.length; i++) {
          str += row[i].goodsName + row[i].standardName + ",";
        }
      } else {
        str = "";
      }

      return str;
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
.opt .el-button {
  margin-bottom: 8px;
  margin-left: 0;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>